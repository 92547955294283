import React from "react";
import "./Telegram.css";
import TelegramLogo from "../assets/Telegram_logo.svg";

function Telegram() {
  function openTelegram() {
    window.open("https://t.me/DanielLinares260", '_blank', 'noopener,noreferrer')
  }

  return (
      <figure className="telegram__logo--wrapper" onClick={() => openTelegram()}>
        <img src={TelegramLogo} alt="" className="telegram__img" />
        <div className="telegram__backdrop">¡Conversa con Nosotros!</div>
      </figure>
  );
}

export default Telegram;
