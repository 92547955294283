import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Asegúrate de usar la ruta correcta

function AdminPagePopUpEdit() {
  const [formData, setFormData] = useState({
    inicioClases: "",
    tituloCurso: "",
    promocionHasta: "",
    descripcion: "",
    precioSoles: "",
    precioDolares: "",
    popupActivo: true,
    tipoCurso: "descriptive",
  });
  const [successMessage, setSuccessMessage] = useState(""); // Estado para el mensaje de éxito

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "popup", "2HGdwoMav0exTdQDOxGv"); // Cambia 'tu_coleccion' y 'tu_documento_id' por los valores reales
        console.log("Intentando obtener el documento con ID:", docRef.id); // Para confirmar el ID

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // Mapear los datos de Firestore al formato del estado
          const mappedData = {
            inicioClases: data.Inicio || "",
            tituloCurso: data.Titulo || "",
            promocionHasta: data.FinPromo || "",
            descripcion: data.Descripcion || "",
            precioSoles: data.PrecioSol || 0,
            precioDolares: data.PrecioDol || 0,
            popupActivo: data.PopUpActivo,
            cursoActivo: data.CursoActivo === "descriptive", // Convertir a booleano
            tipoCurso:
              data.CursoActivo === "descriptive"
                ? "descriptive"
                : "dibujoingenieria", // Mapear el tipo de curso
          };

          setFormData(mappedData); // Actualizar el estado
        } else {
          console.log("No existe el documento"); // El documento no se encontró
        }
      } catch (error) {
        console.error("Error obteniendo el documento:", error); // Manejo de errores
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked; // Capturar el valor actual del checkbox
    setFormData({ ...formData, popupActivo: isChecked }); // Actualizar el estado basado en el valor del checkbox
    console.log("Se cambió a activo:", isChecked);
  };
  

  const handleRadioChange = (e) => {
    setFormData({ ...formData, tipoCurso: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "popup", "2HGdwoMav0exTdQDOxGv");
      await updateDoc(docRef, {
        Inicio: formData.inicioClases,
        Titulo: formData.tituloCurso,
        FinPromo: formData.promocionHasta,
        Descripcion: formData.descripcion,
        PrecioSol: Number(formData.precioSoles), // Asegurar que es número
        PrecioDol: Number(formData.precioDolares), // Asegurar que es número
        PopUpActivo: formData.popupActivo,
        CursoActivo:
          formData.tipoCurso === "descriptive"
            ? "descriptive"
            : "dibujoingenieria",
      });

      setSuccessMessage("Se pudo cambiar con éxito"); // Mostrar mensaje de éxito
      setTimeout(() => setSuccessMessage(""), 3000); // Ocultar mensaje después de 3 segundos
    } catch (error) {
      console.error("Error actualizando el documento:", error);
      setSuccessMessage("Hubo un error al guardar"); // Mostrar mensaje de error
      setTimeout(() => setSuccessMessage(""), 3000); // Ocultar mensaje después de 3 segundos
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ padding: "20px", maxWidth: "400px", margin: "auto" }}
    >
      <div>
        <label htmlFor="inicioClases">Inicio Clases:</label>
        <input
          type="text"
          id="inicioClases"
          name="inicioClases"
          value={formData.inicioClases}
          onChange={handleInputChange}
          required
        />
      </div>

      <div>
        <label htmlFor="tituloCurso">Título Curso:</label>
        <input
          type="text"
          id="tituloCurso"
          name="tituloCurso"
          value={formData.tituloCurso}
          onChange={handleInputChange}
          required
        />
      </div>

      <div>
        <label htmlFor="promocionHasta">Promoción hasta:</label>
        <input
          type="text"
          id="promocionHasta"
          name="promocionHasta"
          value={formData.promocionHasta}
          onChange={handleInputChange}
          required
        />
      </div>

      <div>
        <label htmlFor="descripcion">
          Descripción (Cantidad de clases y horas):
        </label>
        <textarea
          id="descripcion"
          name="descripcion"
          value={formData.descripcion}
          onChange={handleInputChange}
          required
        ></textarea>
      </div>

      <div>
        <label htmlFor="precioSoles">Precio Soles:</label>
        <input
          type="number"
          id="precioSoles"
          name="precioSoles"
          value={formData.precioSoles}
          onChange={handleInputChange}
          required
        />
      </div>

      <div>
        <label htmlFor="precioDolares">Precio Dólares:</label>
        <input
          type="number"
          id="precioDolares"
          name="precioDolares"
          value={formData.precioDolares}
          onChange={handleInputChange}
          required
        />
      </div>

      <div>
        <label>
          <input
            type="checkbox"
            name="popupActivo"
            checked={formData.popupActivo}
            onChange={handleCheckboxChange}
          />
          PopUp Activo
        </label>
      </div>

      <div>
        <p>Tipo de curso:</p>
        <label>
          <input
            type="radio"
            name="tipoCurso"
            value="descriptive"
            checked={formData.tipoCurso === "descriptive"}
            onChange={handleRadioChange}
          />
          Descriptiva
        </label>
        <label>
          <input
            type="radio"
            name="tipoCurso"
            value="dibujoingenieria"
            checked={formData.tipoCurso === "dibujoingenieria"}
            onChange={handleRadioChange}
          />
          Dibujo Ingeniería
        </label>
      </div>
      {successMessage && (
        <span style={{ color: "red", display: "block", margin: "10px 0" }}>
          {successMessage}
        </span>
      )}
      <button type="submit">Guardar</button>
    </form>
  );
}

export default AdminPagePopUpEdit;
