import React, { useState, useEffect } from "react";
import "./PopUp.css";
import Course1 from "../assets/HomeCourse1.jpeg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Asegúrate de usar la ruta correcta

function PopUp() {
  const [isOpen, setIsOpen] = useState(true);
  const [popUpData, setPopUpData] = useState(null); // Estado para los datos dinámicos
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "popup", "2HGdwoMav0exTdQDOxGv"); // Cambia por el ID correcto
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPopUpData(docSnap.data()); // Guardar los datos en el estado
          console.log(docSnap.data())
          if (docSnap.data().PopUpActivo === false) {
            console.log('popUp inactivo')
          }
        } else {
          console.log("No existe el documento");
        }
      } catch (error) {
        console.error("Error obteniendo los datos del popup:", error);
      }
    };

    fetchData();
  }, []);

  function handleClosePopUp() {
    setIsOpen(false);
    navigate("/courses/descriptive");
  }

  if (!popUpData || !popUpData.PopUpActivo || !isOpen) {
    return null;
  }

  return (
    <div id="popUp">
      <div className="popUp__container">
        <h2>¡Inicio de Clases {popUpData.Inicio}!</h2>
        <h3>INSCRÍBETE AHORA</h3>
        <div className="popUp__img-description">
          <div className="popUp__img--wrapper">
            <img src={Course1} alt="Curso" className="popUp__img" />
            <div className="popUp__onSale">¡EN OFERTA!</div>
          </div>
          <div className="popUp__description">
            <div className="popUp__description--border"></div>
            <h1>{popUpData.Titulo}</h1>
            <h2>¡Precio de promoción hasta el {popUpData.FinPromo}!</h2>
            <h2>{popUpData.Descripcion}</h2>
            <h3>
              <span style={{ fontWeight: "700", color: "gold" }}>
                S/. {popUpData.PrecioSol}.00
              </span>
              <br />
              <p
                style={{
                  fontSize: "32px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-10px",
                }}
              >
                ($ {popUpData.PrecioDol}.00)
              </p>
            </h3>
            <button className="popUp__btn" onClick={handleClosePopUp}>
              IR AL CURSO
            </button>
          </div>
        </div>
        <div className="close--popUp" onClick={() => setIsOpen(false)}>
          <span className="popUp__close-icon close__text">Cerrar</span>
          <CloseIcon className="popUp__close-icon" />
        </div>
      </div>
    </div>
  );
}

export default PopUp;
