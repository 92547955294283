import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import PopUp from "../components/PopUp";
import Whatsapp from "../components/Whatsapp";
import Telegram from "../components/Telegram";
import ScrollToTop from "../helpers/ScrollToTop";
import "./NavFootLayout.css"

export default function NavFootLayout () {
    return (
        <main>
            <PopUp />
            <ScrollToTop />
            <Nav />
            <div className="outlet__container">
                <Outlet />
                <Whatsapp />
                <Telegram />
            </div>
            <Footer />
        </main>
    )
}