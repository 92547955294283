import "./Course2.css";
import React, { useEffect, useState } from "react";
import Course2Photo from "../../assets/HomeCourse2.jpg";
import Course2BG from "../../assets/Course2BG.jpg"
import { subject2 } from "../../data/subjects";
import book1 from "../../assets/book1.PNG";
import book2 from "../../assets/book2.PNG";
import book3 from "../../assets/book3.PNG";
import book4 from "../../assets/book4.PNG";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig";
import AnimateHeight from "react-animate-height"
import zoomLogo from "../../assets/zoomLogo.png";
import BBVA from "../../assets/BBVA.png";
import BCP from "../../assets/BCP.png";
import Interbank from "../../assets/Interbank.png";
import Yape from "../../assets/Yape.png";
import Plin from "../../assets/Plin.png";
import Paypal from "../../assets/Paypal.png";
import Western from "../../assets/Western.png";
import Video from "../../components/Video";

function Course2() {
  const [height, setHeight] = useState(0);

  function listHTML(object) {
    let courseSubjectsEl = document.querySelector(".course__list");
    let newArr = [];
    for (const text in object) {
      newArr.push(`<li class="course__item">
      <h4 class="course__item--title" style>${text}:</h4> <p>${object[text]}</p>
    </li>`);
    }
    courseSubjectsEl.innerHTML = newArr.join("");
  }

  useEffect(() => {
    listHTML(subject2);
  });


  function handleClick() {
    let subjectTitleEl = document.querySelector(".subject__title");
    setHeight(height === 0 ? "auto" : 0);
    if (subjectTitleEl.classList.contains("subject__title--active")) {
      subjectTitleEl.classList.remove("subject__title--active");
    } else {
      subjectTitleEl.classList += " subject__title--active";
    }
    logEvent(analytics, "Click Termario", {
      buttonName: "Abrir/Cerrar Temario",
  })
  }


  return (
    <div className="row">
      <img src={Course2BG} alt="" className="courses__bg" />
      <div className="course__container" style={{ flexDirection: "row-reverse"}}>
          <h2 className="course__title course2__title">
            Dibujo En&nbsp;<span>Ingeniería</span>
          </h2>
        <div className="course__img--wrapper">
          <img src={Course2Photo} alt="" className="course__img" />
        </div>
        <div className="course__description">
          <p>
            ¿Cómo se aplica el dibujo en la ingeniería? El Dibujo técnico es una
            de las herramientas fundamentales en el desarrollo de la Ingeniería
            por ser la base de la comunicación gráfica de las ideas o proyectos
            a ejecutar, de la claridad y exactitud de los planos depende en gran
            medida el correcto desarrollo de los proyectos o procesos a
            realizar.
          </p>
          <div className="course__books">
              <figure className="course__book">
                <img src={book1} alt="" className="course__book--img" />
              </figure>
              <figure className="course__book">
                <img src={book2} alt="" className="course__book--img" />
              </figure>
              <figure className="course__book">
                <img src={book3} alt="" className="course__book--img" />
              </figure>
              <figure className="course__book">
                <img src={book4} alt="" className="course__book--img" />
              </figure>
            </div>

        </div>
        
      </div>
      <div className="course__video-button">
        <h3 className="course__video-button--title">PRIMERA CLASE DEMOSTRATIVA</h3>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/R73WFPSdhqU?si=UIU34Dngk22xMZbP" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <button className="course__btn" onClick={handleClick} style={{marginTop:"40px"}}>
              Ver Temario Completo
            </button>
            <AnimateHeight
            duration={300}
            height={height}
            className="course__subjects"
          >
            <div className="course__subjects--drop">
              <h3 className="subject__title">TEMARIO DEL CURSO</h3>
              <ul className="course__list"></ul>
              <button className="course__btn close__btn" onClick={handleClick}>CERRAR TEMARIO</button>
            </div>
          </AnimateHeight>
      </div>
      <div className="course__container--schedule-price">
          <div className="courses__price-1">
            <h3>
              ¡PRECIO <span>ESPECIAL</span> 12 CLASES (24 HORAS) EN VIVO!
            </h3>
            <p>
              <span>S/.100</span> SOLES
            </p>
            {/* <span>Precio Antiguo: S/. 240</span> */}
          </div>
        </div>
        <div className="courses__payment-schedule">
          <div className="courses__payment--container">
            <h3>MEDIOS DE PAGO</h3>
            <div className="courses__payment-methods--wrapper">
              <h2>Número de Cuenta Soles</h2>
              <div className="payment__method">
                <figure className="payment__logo--wrapper">
                  <img src={BBVA} alt="" className="payment__logo" />
                </figure>
                <p>0011-0814-0231726786</p>
              </div>
              <div className="payment__method">
                <figure className="payment__logo--wrapper">
                  <img src={BCP} alt="" className="payment__logo" />
                </figure>
                <p>19491933407025</p>
              </div>
              <div className="payment__method">
                <figure className="payment__logo--wrapper">
                  <img src={Interbank} alt="" className="payment__logo" />
                </figure>
                <p>8983202856189</p>
              </div>
              <div className="payment__method">
                <figure className="payment__logo--wrapper">
                  <img src={Yape} alt="" className="payment__logo" />
                </figure>
                <figure className="payment__logo--wrapper">
                  <img src={Plin} alt="" className="payment__logo" />
                </figure>
                <p>+51 921 417 139 <br /> Carlos Daniel Linares Q.</p>
              </div>
              <div className="payment__method">
                <figure className="payment__logo--wrapper paypal--wrapper">
                  <img
                    src={Paypal}
                    alt=""
                    className="payment__logo paypal__logo"
                  />
                </figure>
                <p>carlosdlq260@gmail.com</p>
              </div>
              <div className="payment__method">
                <img src={Western} alt="" className="western__logo"/>
                <p>
                  Si eres del extranjero ponte en contacto con +51 921 417 139
                </p>
              </div>
            </div>
          </div>
          <div className="courses__schedule">
            <h3>
              <span>HORARIO DE CLASES</span>
            </h3>
            <div className="courses__schedule--container">
              <ul>
                <li className="schedule__item">
                  <span>Viernes</span>
                  <br />
                  <span className="span__hour">8:00pm - 10:00pm</span>
                </li>
                <li className="schedule__item">
                  <span>Sábado</span>
                  <br />
                  <span className="span__hour">8:00pm - 10:00pm</span>
                </li>
                <li className="schedule__item">
                  <span>Domingo</span>
                  <br />
                  <span className="span__hour">8:00pm - 10:00pm</span>
                </li>
              </ul>
              <figure className="courses__zoom--wrapper">
                <img src={zoomLogo} alt="" className="zoom__logo" />
              </figure>
            </div>
            <p>
              <span className="span__blue">12 CLASES</span> EN VIVO VÍA ZOOM
              TODAS LAS SESIONES SERÁN{" "}
              <span className="span__blue">GRABADAS</span>
            </p>
          </div>
        </div>
        <div className="courses__steps--container">
          <h3 style={{marginBottom:"16px"}}>¿CÓMO ME MATRICULO?</h3>
          <div className="courses__steps--wrapper">
            <div className="courses__step--1">
              <h2>
                Paso <span>1</span>
              </h2>
              <div className="step__description">
                <p>
                  Realiza el pago en cualquiera de nuestras cuentas antes
                  mencionadas
                </p>
                <div className="step__logos">
                  <img src={BBVA} alt="" className="step__logo" />
                  <img src={BCP} alt="" className="step__logo" />
                  <img src={Interbank} alt="" className="step__logo" />
                  <img src={Yape} alt="" className="step__logo" />
                  <img src={Plin} alt="" className="step__logo" />
                  <figure className="steps__paypal--wrapper">
                    <img src={Paypal} alt="" className="step__logo step__paypal" />
                  </figure>
                </div>
                <p style={{marginTop:"12px"}}>
                  <span className="step__description--span">IMPORTANTE</span>
                  <ul>
                  <li>Pagos por transferencia bancaria/pago por agentes/Plin/Yape sin comisión.</li>
                    {/* <li>Pagos por agente (Bodegas, Farmacias, etc.) se adiciona 9 soles al precio final.</li> */}
                  </ul>
                </p>
              </div>
            </div>
            <div className="courses__step--2">
              <h2>
                Paso <span>2</span>
              </h2>
              <div className="step__description step__description--2">
                <p>
                  Envíanos un mensaje indicando tu <span style={{color:"#09082D", fontWeight:"700"}}>Nombre Completo</span>, <span style={{color:"#09082D", fontWeight:"700"}}>Correo Electrónico</span>, <span style={{color:"#09082D", fontWeight:"700"}}>Universidad de Procedencia</span> y <span style={{color:"#09082D", fontWeight:"700"}}>Especialización</span> y <span style={{color:"#09082D", fontWeight:"700"}}>Comprobante de Pago</span> a nuestro
                  WhatsApp <span style={{color:"#09082D", fontWeight:"700"}}>+51 972 302 704</span> para formar parte del grupo de estudio más completo y
                  didáctico.
                </p>
              </div>
            </div>
            <p>
              ¡Listo! Una vez matriculado se te enviará todos los beneficios y
              materiales de estudio así como información adicional en las
              siguientes 24 horas.
            </p>
          </div>
        </div>
    </div>
  );
}

export default Course2;
